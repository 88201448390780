import Pricing from 'components/Pricing/Pricing'
import React from 'react'
import { graphql } from 'gatsby'

export default Pricing

export const query = graphql`
    query getPricingDataQuery($language: String!) {
        pageData: allMdx(filter: { fields: { slug: { regex: "/^/pricing/" } }, frontmatter: { title: { ne: "" } } }) {
            nodes {
                body
                id
                internal {
                    contentFilePath
                }
                headings {
                    depth
                    value
                }
                fields {
                    slug
                }
                frontmatter {
                    title
                    logoFileName
                    subtitle
                    description
                    exclude
                    seoDescription
                    seoimage
                    seoTitle
                    stickeyMenu
                    pricingMainCTA {
                        title
                        url
                    }
                    groupsToShow
                    showCTA
                }
                parent {
                    ... on GoogleDocs {
                        id
                        products {
                            description
                            docs_url
                            image_url
                            inclusion_only
                            contact_support
                            addons {
                                contact_support
                                description
                                docs_url
                                image_url
                                inclusion_only
                                name
                                type
                                unit
                                plans {
                                    description
                                    docs_url
                                    image_url
                                    name
                                    plan_key
                                    product_key
                                    unit
                                    features {
                                        description
                                        key
                                        name
                                    }
                                    tiers {
                                        current_amount_usd
                                        current_usage
                                        flat_amount_usd
                                        unit_amount_usd
                                        up_to
                                    }
                                }
                            }
                            name
                            type
                            unit
                            plans {
                                description
                                docs_url
                                features {
                                    description
                                    key
                                    limit
                                    name
                                    note
                                    unit
                                }
                                free_allocation
                                image_url
                                included_if
                                name
                                plan_key
                                product_key
                                tiers {
                                    current_amount_usd
                                    current_usage
                                    flat_amount_usd
                                    unit_amount_usd
                                    up_to
                                }
                                unit
                            }
                        }
                        images {
                            name
                            publicURL
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                        images2: images {
                            name
                            publicURL
                            childImageSharp {
                                gatsbyImageData(width: 265, height: 265)
                            }
                        }
                    }
                }
            }
        }
        locales: allLocale(filter: { language: { eq: $language }, ns: { in: ["page-pricing", "common"] } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        layoutData: allMdx(filter: { fields: { slug: { regex: "/^/layout/" } } }) {
            nodes {
                parent {
                    ... on GoogleDocs {
                        id
                        layout {
                            brand
                            fonts {
                                fontFamily
                                src {
                                    url
                                    format
                                }
                                fontWeight
                                fontStyle
                                fontDisplay
                            }
                            variables {
                                key
                                value
                            }
                            sidebars {
                                name
                                items {
                                    name
                                    url
                                    enable
                                    badge {
                                        title
                                        className
                                        title_ar
                                    }
                                    children {
                                        name
                                        url
                                        enable
                                        badge {
                                            title
                                            className
                                            title_ar
                                        }
                                        children {
                                            name
                                            url
                                            enable
                                            badge {
                                                title
                                                className
                                                title_ar
                                            }
                                            children {
                                                name
                                                url
                                                enable
                                                badge {
                                                    title
                                                    className
                                                    title_ar
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            sidebars_ar {
                                name
                                items {
                                    name
                                    url
                                    enable
                                    badge {
                                        title
                                        className
                                        title_ar
                                    }
                                    children {
                                        name
                                        url
                                        enable
                                        badge {
                                            title
                                            className
                                            title_ar
                                        }
                                        children {
                                            name
                                            url
                                            enable
                                            badge {
                                                title
                                                className
                                                title_ar
                                            }
                                            children {
                                                name
                                                url
                                                enable
                                                badge {
                                                    title
                                                    className
                                                    title_ar
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            variables {
                                key
                                value
                            }
                            logoFileName
                        }
                    }
                }
            }
        }
    }
`
